<template>
  <div class="user-not-logged">
    <BaseButton
      id="header-mobile-login-btn"
      class="base-button--btn-outline"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'login')"
    >
      Đăng nhập
    </BaseButton>
    <BaseButton
      id="header-mobile-register-btn"
      class="base-button--btn-filled"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'register')"
    >
      Đăng ký
    </BaseButton>
  </div>
</template>
<script setup>
import BaseButton from '~/components/common/base-button.vue'
import { MODAL_TYPES } from '~/config/constant'
const { onClickAuthor } = useHeader()
</script>
<style lang="scss" scoped src="assets/scss/components/mobile/header/user-not-logged.scss" />
